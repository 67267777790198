html,
body {
  text-align: center;
  height: 100%;
  margin: 0;
}

.main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
}

.main .row-header {
  flex: 0 1 auto;
}

.main .row-content {
  flex: 1 1 auto;
}

.main .row-footer {
  flex: 0 1 30px;
}
